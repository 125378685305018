import { Matrix, Metric, Department } from '@/Models'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import DailyCalendar from '@/components/DailyCalendar/index.vue'
import Datepicker from 'vue2-datepicker'
import { BalancedScoreCard } from '@/Models/BalancedScoreCard'
import { Perspectives } from '@/Models/Perspectives'
import http from '@/services'
import { watch } from 'vue'
@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    DailyCalendar,
    Datepicker,
  },
})
export default class MectricModal extends Vue {
  @Prop({ required: true }) departments!: Department[]
  @Prop({ required: true }) metric!: Metric
  @Prop({ required: true }) matrix!: Matrix
  @Prop({ required: true }) action!: string
  @Prop({ required: false }) metricType!: string

  title = this.action == 'create' ? 'Ingrese una métrica' : 'Editar la Métrica'
  iconLeft = this.action == 'create' ? 'plus' : 'pencil'
  name = ''
  dataBalance: Array<BalancedScoreCard> = []
  metricTypes: Array<string> = ['diary', 'weekly', 'monthly']
  metricTypeSelected = ''
  metricValues: Array<string> = ['percentage', 'numeric']
  metricValueSelected: string | null = null
  date: string = new Date().getFullYear().toString()
  nominal = 0
  upperLimit = 0
  lowerLimit = 0
  showLimits = true
  onBSC = false
  // perspectives: Perspectives = new Perspectives()
  // balanceScoreCard: BalancedScoreCard = new BalancedScoreCard()
  // perspectiveSelected = 0
  // BSCSelected = {
  //   id: 0,
  //   title: '',
  //   perspectives: [],
  // }
  // perspectiveTypes: string[] = [
  //   'customer relationship',
  //   'financial',
  //   'internal process',
  //   'education & growth',
  // ]

  // showPerspective = false

  async mounted() {
    // await this.getPerspectives()
    this.metricTypeSelected = this.metricType
    this.metricValueSelected = this.metric.metric_value_type
    this.nominal = this.metric.nominal_value
    this.lowerLimit = this.metric.lower_limit
    this.upperLimit = this.metric.upper_limit
    //this.onBSC = this.metric.in_balcance_score_card
    //this.BSCSelected = this.metric.balance_scorecard as any

    //console.log(this.nominal, 'MOUNTED NOMINAL VALUE')
    // console.log(this.lowerLimit, 'LOWER')
    // console.log(this.upperLimit, 'UPPER')
    if (this.action !== 'create') {
      this.showLimits = true
      this.date = this.metric.matrix_metric.date.toString()
      ;(this.$refs['calendar'] as any)['events'] =
        await this.metric.metric_dataset.data_set.map((x: any) => ({
          ...x,
          date: new Date(x.date),
        }))
      ;(this.$refs['calendar'] as any)['date'] = await new Date(
        this.date + '-01-02',
      )
      // if (typeof this.metric.balance_scorecard == 'number') {
      //   this.BSCSelected = this.dataBalance.filter(
      //     (x: any) => x.id === this.metric.balance_scorecard,
      //   )[0] as any
      // } else {
      // if (this.metric.balance_scorecard && this.metric.balance_scorecard.id) {
      //   this.BSCSelected = this.dataBalance.filter(
      //     (x: any) => x.id === this.metric.balance_scorecard.id,
      //   )[0] as any
      // }
    }
    // if (
    //   typeof this.metric.perspective == 'object' &&
    //   this.metric.perspective &&
    //   this.metric.perspective.id
    // ) {
    //   this.perspectiveSelected = this.metric.perspective.id as number
    // } else {
    //   this.perspectiveSelected = this.metric.perspective_id
    // }
  }

  // showPerspetives() {
  //   this.showPerspective = false
  //   setTimeout(() => {
  //     this.showPerspective = true
  //   }, 400)
  // }

  addNominal() {
    //console.log(this.nominal, 'ADDnominal')
    this.showLimits = true
    this.lowerLimit = 0
    this.upperLimit = 0
  }

  async loadDepartments() {
    try {
      const res = await http.get('panel/department/')
      if (!res.data) throw new Error(res.data)
      console.log(res)
      this.departments = res.data.map((x: any) => ({
        ...x,
      }))
    } catch (error: any) {
      if (error.response && error)
        this.$buefy.toast.open({
          message: error.response.data.detail,
          type: 'is-danger',
        })
    }
  }

  @Watch('metricType')
  onPropertyChange() {
    this.metricTypeSelected = this.metricType
  }

  // async getBSCList() {
  //   try {
  //     const res = await http.get('balance-score/balance-score-card/')
  //     if (!res.data) throw new Error(res.data)
  //     this.dataBalance = res.data.map((x: BalancedScoreCard) => ({
  //       ...x,
  //     }))
  //     if (this.perspectives.title)
  //       this.balanceScoreCard.perspectives = (
  //         this.perspectives.title as any
  //       ).map((x: any) => ({
  //         title: x,
  //       }))
  //   } catch (error: any) {
  //     if (error.response && error) {
  //       this.$buefy.toast.open({
  //         message: error.response.data.detail,
  //         type: 'is-danger',
  //       })
  //     }
  //   }

  // }
  confirmation() {
    this.metric.nominal_value = this.nominal
    this.metric.lower_limit = this.lowerLimit
    this.metric.upper_limit = this.upperLimit

    //this.metric.in_balcance_score_card = false

    // this.metric.in_balcance_score_card = this.onBSC
    // if (this.onBSC) {
    //   this.metric.balance_scorecard = this.dataBalance.filter(
    //     (x: any) => x.id === this.BSCSelected.id,
    //   )[0].id as any
    //   this.metric.perspective_id = this.perspectiveSelected
    // }

    //this.metric.perspective = this.perspectiveSelected as any

    this.metric.matrix_metric.date = this.metric.date = new Date(this.date)
    this.metric.matrix_metric.metric_type = this.metricTypeSelected
    this.metric.metric_value_type = this.metricValueSelected
      ? this.metricValueSelected
      : ''

    this.metric.metric_dataset = {
      data_set: (this.$refs['calendar'] as any)['events'],
    }

    this.$emit('create-edit-metric', this.metric)
    this.$emit('close')
  }

  showAddDepartment() {
    this.$buefy.dialog.prompt({
      message: `Departamento`,
      inputAttrs: {
        placeholder: 'Ingrese el departamento',
        maxlength: 80,
        value: this.name,
      },
      confirmText: 'Agregar',
      cancelText: 'Cancelar',
      onConfirm: (value: any) => {
        if (value) this.$emit('add-department', { name: value, company_id: 1 })
      },
    })
  }
}
