import { render, staticRenderFns } from "./MetricModal.vue?vue&type=template&id=4c80b94a&"
import script from "./MetricModal.ts?vue&type=script&lang=ts&scoped=true&"
export * from "./MetricModal.ts?vue&type=script&lang=ts&scoped=true&"
import style0 from "./MetricModal.vue?vue&type=style&index=0&id=4c80b94a&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports